<template>
  <div class="shenshi-wrapper">
    <h2 class="shenshi-title">北京甚智医学科技有限公司关于www.ai-ckd.com 和“智慧肾脏之家”访问者的数据保护声明</h2>
    <p class="shenshi-p">作为北京甚智医学科技有限公司网站（www.ai-ckd.com）和公众号”智慧肾脏之家”的访问者，北京甚智医学科技有限公司（以下简称“我们”）将收集和使用您的某些个人数据。</p>
    <p class="shenshi-p">本数据保护声明提示您在访问我们的网站时关于个人数据处理的相关事宜。</p>
    <p class="shenshi-p">请注意，我们还可能在其他情况下处理您的个人数据，例如，当您以医疗健康专业人士的身份与我们交流互动时。请参阅在这些情况下有关您的个人数据处理的详细信息。</p>
    <p class="shenshi-p">我们进一步提示您，我们的网站包含了与本数据保护声明无关的外部网站链接。此外，北京甚智医学科技有限公司网站和公众号提供的数据保护声明可能有所不同。您访问该网站时应遵守各自提供的数据保护声明。对于这些网站内容我们不承担数据保护义务。我们不干涉这些网站的运营商是否遵守数据保护规定。</p>
    <h3>一、我们为什么收集和使用您的数据</h3>
    <p class="shenshi-p">我们收集和使用您的数据，旨在用于以下目的：</p>
    <p>• 根据您的调查或要求进行确认、处理和回复，履行我们的合规要求</p>
    <p>• 为了您使用的设备在网站上正常运行</p>
    <p>• 为您提供最好的服务并提升您的用户体验</p>
    <p>• 优化我们的网站</p>
    <p>二、我们会在什么情况下收集和使用您的个人数据</p>
    <p class="shenshi-p">我们可能会在下列情况下收集和使用您的个人数据：</p>
    <p class="shenshi-p">当您访问我们的网站时我们会收集您访问我们网站的数据。我们这样做的目的是根据您使用的设备及其所有功能来优化网站，或者针对您当前或未来的浏览会话来保存您的偏好。</p>
    <p class="shenshi-p">在您访问我们的网站过程中，我们会收集以下互联网协议数据：</p>
    <p>• 您服务提供商的名称，包括IP地址</p>
    <p>• 引导您访问我们站点的网站</p>
    <p>• 在我们网站上访问的网页</p>
    <p>• 您的网络浏览器类型和您的访问日期和时间</p>
    <p class="shenshi-p">通过删除最后三位数字，我们将对您的IP地址做匿名化处理。这样，我们就无法再直接识别您的个人身份。</p>
    <p class="shenshi-p">对于使用者可能需要访问网站的一些特定区域时，我们可能会获取并保存其他数据。但是否提交个人数据纯属自愿行为。</p>
    <p class="shenshi-cu">我们使用Cookie收集的信息</p>
    <p class="shenshi-p">我们会在网站上使用Cookie。Cookies是您的网络浏览器在您的计算机本地上存储的小型文本文件。</p>
    <p class="shenshi-p">您可以拒绝使用本网站的某些Cookie。请注意，如果您选择不接受使用Cookie，则可能无法体验本网站的全部功能。如果您选择删除Cookie，则可能需要再次确认某些对话框。</p>
    <p class="shenshi-p">您的cookie设置始终与您所使用的网络浏览器有关，如果在下次使用本网站时使用不同的网络浏览器，则这些设置将不会产生任何影响。另外，您可以管理您的网络浏览器或移动设备上的设置。在这里可以随时自行删除cookie。</p>
    <p class="shenshi-p">我们使用以下类型的cookie。</p>
    <p>• 绝对必要的Cookie</p>
    <p class="shenshi-p">这种类型的cookie是网站运行的必要条件，仅供我们在会话期间使用，即所谓的第一方会话cookie。这些cookie有助于提高页面加载速度，并限制用户发起的会话数量，从而防止网站超载。这些cookie在会话期间保持有效，并在浏览器关闭后自动删除。</p>
    <p>• 功能性Cookie</p>
    <p class="shenshi-p">功能性cookie保存您在访问本网站时进行的选择，从而为您提供更好的功能和个性化特征。</p>
    <p class="shenshi-p">我们使用“cookie通知人”来保存您同意或不同意我们网站使用cookie的决定。单击两个选项中的任何一个时将自动保存。您接收的cookie将自接收之日起保存一年。如果您决定拒绝接收我们的cookie，您的这一决定将被保存30天。在此期间将不再显示cookie信息横幅。</p>
    <p>• 分析性Cookie</p>
    <p class="shenshi-p">分析性cookie允许我们分析网站的使用方式。这使我们能够优化其性能并提高用户体验。我们的网站使用开源网络分析工具。我们可以使用该工具跟踪访问者使用我们网站网页的频率、访问者最常访问的网页以及他们是否从我们的网页中捕获了错误信息。将您的IP地址删除最后三位数字，以匿名化的方式进行处理。这样，我们就无法再直接识别您的个人身份。</p>
    <p class="shenshi-p">您提供给我们的信息</p>
    <p class="shenshi-p">我们会收集和处理您主动提供给我们的数据，例如，在填写在线表格或通过其他通信方式（如电子邮件、电话或邮件）与我们联系时。这些数据包括：</p>
    <p>• 名字和姓氏</p>
    <p>• 性别</p>
    <p>• 联系方式和地址信息(包括地址数据、电子邮件地址、电话、传真号码)</p>
    <p>• 职业</p>
    <p>• 请求类型以及为回复您的调查而可能提供的进一步信息</p>
    <h3>三、个人数据的使用和传播，数据处理目的和法律依据</h3>
    <p class="shenshi-p">我们仅在必要和许可范围内，将您的个人数据用于网站技术管理、客户管理、产品调查以及市场营销。未经您的允许，我们不会向北京甚智医学科技有限公司以外的第三方提供您的信息，更不会用于宣传目的。</p>
    <p class="shenshi-p">我们将根据《中华人民共和国网络安全法》的要求处理您的个人数据。</p>
    <p class="shenshi-p">如果我们有法律义务或接到法院命令，我们会将您的数据转交给主管机构。</p>
    <h3>四、我们会将您的数据保留多长时间</h3>
    <p class="shenshi-p">您的联系信息将在调查结束后保存长达6个月，除非法律规定将数据保留更长的时间。通过cookie收集的信息将在上述cookie有效期间内予以保存。</p>
    <h3>五、数据的安全性</h3>
    <p class="shenshi-p">我们在技术和组织方面采取安全措施，保护我们管理的个人数据不会被操控，不会丢失和损毁，也不会未经授权而被访问或披露。只有少数授权人员才能获取您的数据。</p>
    <p class="shenshi-p">原则上，通过互联网传输我们的网站或您在我们网站上输入的内容是不加密的。因此不排除第三方看到并/或获取传输数据的可能性。我们建议您考虑这一方面，决定是否通过互联网向我们传输数据，以及传输哪些数据。</p>
    <p class="shenshi-p">我们的安全措施将会随着技术发展不断的完善和调整。</p>
    <h3>六、请求、调查和投诉</h3>
    <p class="shenshi-p">您对您的个人数据具有一定的权利，具体视情况而定。您有权：</p>
    <p>• 要求访问您的个人数据</p>
    <p>• 要求修改您的个人数据</p>
    <p>• 要求删除您的个人数据（提示：对于数据保存期限，在删除数据前我们会按照相关法律规定的期限将其冻结。）</p>
    <p>• 要求限制处理您的个人数据。</p>
    <p>• 数据迁移</p>
    <p>• 根据具体情况提出反对意见</p>
    <p class="shenshi-p">您可以通过使用数据保护联络表在线行使这些权利。此外,您还有权向我们的数据保护官或监管机构提出控诉。</p>
    <h3>七、有关特殊情况下的更多信息</h3>
    <p class="shenshi-p">我们需要您的个人数据是为了让您能够访问本网站，并可以跟进您的查询。如果您不予提供您的个人数据，网站将无法运行，我们可能无法回应或妥善处理您的请求。</p>
    <p class="shenshi-cu">本数据保护声明变更</p>
    <p class="shenshi-p">由于我们对您个人数据的收集和使用可能会随时间发生变化，为了始终正确反映我们的数据处理实践，我们可能会另行修改本数据保护声明，请您多加留意本数据声明。</p>
    <p class="shenshi-cu">管理人和联系人</p>
    <p class="shenshi-p">处理个人数据的管理人和责任实体的联系邮箱是：</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>